import React from "react"

const Button = ({
    variant = "primary",
    icon,
    className,
    clickHandler = null,
    children,
}) => {
    const findClasses = className ? ` ${className}` : ""
    let classes = `btn btn-${variant}${findClasses} font-bold font-inter`
    let baseClasses = ` py-[11px] px-[19px] border-[3px] border-solid rounded-none self-start transition-all delay-75 ease-in-out leading-none`

    switch (variant) {
        case "primaryMenu":
            classes = classes
                .concat(baseClasses)
                .concat(
                    " text-sm text-white bg-msqred border-msqred hover:bg-hotRed hover:border-hotRed"
                )
            break
        case "secondaryMenu":
            classes = classes
                .concat(baseClasses)
                .concat(
                    " text-sm text-msqblack bg-white border-msqblack hover:text-white hover:bg-msqblack hover:border-msqblack"
                )
            break
        case "linkMenu":
            classes = className.concat(
                " font-inter text-msqblack text-base link-underline"
            )
            break
        default:
        case "primary":
            classes = classes
                .concat(baseClasses)
                .concat(
                    " text-base text-white bg-msqred border-msqred hover:bg-hotRed hover:border-hotRed"
                )
            break
        case "secondary":
            classes = classes
                .concat(baseClasses)
                .concat(
                    " text-base text-msqblack bg-white border-msqblack hover:text-white hover:bg-msqblack hover:border-msqblack"
                )
            break
        case "text":
            classes = classes.concat(
                " text-base flex justify-start items-center gap-[10px] text-msqblue bg-none border-none px-0 capitalize hover:text-msqred has-icon icon-arrow"
            )
            break
            case "lowkey":
                classes = classes
                .concat(baseClasses)
                .concat(
                    " text-base text-msqblack text-normal bg-white !border-[1px] border-solid border-[#999] px-0 capitalize hover:bg-msqyellow font-normal"
                )
    }
    return (
        <button className={classes} onClick={clickHandler}>
            {children}
        </button>
    )
}

export default Button
