import React from "react"
// Import Components
import Link from "@/components/atoms/Link";
import Button from "@/components/atoms/Button"
import MegaMenu from "@/components/organisms/MegaMenu"
// Import Images
import Logo from "@/images/msq-logo.svg"

const Header = ({ mobileMenuOpen, setMobileMenuOpen }) => {
    return (
        <header className="sticky top-0 px-8 lg:px-4 flex justify-between items-center bg-white h-[98px] shadow-default z-[100]">
            <div className="py-4">
                <Link className="" to={"/"} context="header">
                    <img src={Logo} className="w-[140px]" alt="ModSquad Logo" />
                </Link>
            </div>

            {/* Centered Desktop Nav Group */}
            <MegaMenu />

            {/* Right CTAs & Hamburger */}
            <div className=" flex justify-end items-center gap-4">
                <Link
                    context="header"
                    href="https://join.modsquad.com/join-the-mods"
                    className="hidden md:block"
                >
                    <Button variant="secondaryMenu">Join the Mods</Button>
                </Link>

                <Link to="/contact" className="hidden md:block" context="header">
                    <Button
                        variant="primaryMenu"
                        // onClick={() => setContactMenuOpen(!contactMenuOpen)}
                        // className={contactMenuOpen ? "open" : ""}
                        //  onClick={() => setContactMenuOpen(!contactMenuOpen)}
                        //  className={contactMenuOpen ? "open" : ""}
                    >
                        Contact us
                    </Button>
                </Link>
                {/* hamburger */}
                <div
                    id="hamburger"
                    className={`block lg:hidden ${mobileMenuOpen ? "open" : ""}`}
                    role="button"
                    type="button"
                    tabIndex="0"
                    aria-expanded={mobileMenuOpen ? "true" : "false"}
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    onKeyDown={(e) =>
                        e.key === "Enter" && setMobileMenuOpen(!mobileMenuOpen)
                    }
                >
                    <span className="bg-msqblack"></span>
                    <span className="bg-msqblack"></span>
                    <span className="bg-msqblack"></span>
                    <span className="bg-msqblack"></span>
                </div>
            </div>
        </header>
    )
}

export default Header
